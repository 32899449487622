import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Buttons from "../../components/controls/Button";
import { getRobosList, getCoveringRepsList } from '../../graphql/queries'
import { ASYNC_STORAGE, USER_TYPE, WORKFLOW_TYPE, APPLICATION_VERSION, THA_APPROACH } from "../../constants";
import { MAKOTraking, updateMICS } from "../../graphql/mutations"
import { ACTION_CREATORS as userCase } from "../../redux/reducers/cases";
import getCaseById from "../../utils/calendar";
import { useDispatch } from "react-redux";
import { TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from "@material-ui/core/Typography";
import Modal from "./Modal"
import CardContent from '@material-ui/core/CardContent';
import NativeSelect from '@material-ui/core/NativeSelect';
import _ from "lodash"
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Chip } from "@material-ui/core";


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MyChip = (props) => {
    const customWidth = props.width ? { width: props.width } : null;
    return (
        <Chip variant="outlined" size="small" style={customWidth} {...props} />
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: '97%',
    },
    gridItem: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        margin: "50px",
    },
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },


}));


const MAKOTracking = ({ caseDetails, displaySnackBarMessage }) => {
    const classes = useStyles();
    const [CoveringRep, setCoveringRep] = React.useState([]);
    const [CoveringRepName, setCoveringRepName] = React.useState("");
    const [CRP, setCRP] = useState([])
    const [SupportMP, setSupportMP] = useState([]);
    const [MPpresent, setMPpresent] = useState({});
    const [SRpresent, setSRpresent] = useState(null || '');
    const [loader, setLoader] = React.useState(false);
    const [suppMPS, setsuppMPS] = React.useState("");
    const [disabled, setDisabled] = React.useState(false);
    const [rob, setRob] = useState({})
    const [roboOptions, setRoboOptions] = useState([])
    const [pop, setPop] = useState(false)
    const [workflow, setWorkflow] = useState([0]);
    const [disableControl, setDisableControl] = useState(false);
    const [procedureVersion, setProcedureVersion] = useState('');
    const [THAapproach , setTHAapproach] = useState('');

    const dispatch = useDispatch();
    const GetCaseById_Set = (response) =>
        dispatch(userCase.setCaseDetail(response));

    const current_user = JSON.parse(
        localStorage.getItem(ASYNC_STORAGE.CURRENT_USER) || "{}"
    );

    useEffect(() => {
        const tprList = caseDetails.tprId.length > 0 && caseDetails.tprId?.sort((a , b) => {return a.tpr_name.split('-')[2] < b.tpr_name.split('-')[2] ? 1 : -1})[0];
        setProcedureVersion(caseDetails?.procedure_version__c || tprList?.version || '');
        setTHAapproach(caseDetails?.tha_approach__c || tprList?.approach || '');
        setDisableControl(caseDetails.status === "Completed");
    }, [caseDetails]);

    const fetchData = async () => {
        try {
            const [coveringRepsRes] = await Promise.all([
                getCoveringReps()
            ]);
            const coveringReps = coveringRepsRes || [];
            setCoveringRep(coveringReps);
            if (current_user) {
                if (!CoveringRepName || CoveringRepName.length === 0) {
                    const userSFIDs = current_user.personas.toString().includes(USER_TYPE.SALES_ASSOCIATE) ||
                        current_user.personas.toString().includes(USER_TYPE.MPS)
                        ? current_user.sfids
                        : [];
                    setCoveringRepName(userSFIDs);
                }
            }
            if (caseDetails != null) {
                const reps = caseDetails.coveringRepsSFIDs.split(',');
                setCoveringRepName(reps);
            }
        } catch (error) {
            console.error(error);
        }
        setLoader(false)
    };
    const getCoveringReps = async () => {
        try {
            const result = await getCoveringRepsList();
            let coveringReps = [];
            if (result) {
                coveringReps = _.uniqBy(result.get_covering_reps.map(item => ({
                    title: item.userName,
                    id: item.userID,
                })), "id");
            }
            setCoveringRep(coveringReps)
            return [...coveringReps];
        } catch (error) {
            console.error(error);
        }
    };


    const handleMPpresent = (event, newValue) => {
        setMPpresent(newValue);
    };
    const handleWorkflow = (event) => {
        setWorkflow(event.target.value);
    }
    const handleSRpresent = (event) => {
        setSRpresent(event.target.value)
    }

    const handleSupportMPS = (event, newValue) => {
        setSupportMP(event);
    };
    const handleSubmit = async () => {
        if (!MPpresent || !MPpresent.value) {
            displaySnackBarMessage(
                "error",
                "Please Select MPS Present During Surgery."
            );
            return;
        }
        if (!rob || !rob.value) {
            setPop(true);
            return;
        }
        if (SRpresent === "") {
            displaySnackBarMessage(
                "error",
                "Please Select Sales Rep Present During Surgery."
            );
            return;
        }
        if (!procedureVersion || procedureVersion.trim().length === 0) {
            displaySnackBarMessage(
                "error",
                "Please Select Procedure Application Version ."
            );
            return;
        }
        setLoader(true);
        try {
            let payloadRob = {
                external_id__c: caseDetails.externalId,
                sfid: caseDetails.sfId,
                rob__c: rob.value,
                tha_approach__c: THAapproach,
                procedure_version__c : procedureVersion,
            };

            let payload = {
                external_id__c: caseDetails.externalId,
                sfid: caseDetails.sfId,
                coveringuser_present__c: MPpresent.label,
                coveringuser_supporting__c: suppMPS,
                sales_rep_present__c: SRpresent,
                procedure_type__c: workflow && workflow !== '' ? workflow : "",
            };

            await MAKOTraking(payload);
            await updateMICS(payloadRob);
            displaySnackBarMessage("success", "Mako Tracking saved successfully.")
            const res = await getCaseById(caseDetails.externalId, caseDetails.sfId);
            GetCaseById_Set(res.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoader(false);
        }
        setLoader(false);

    }
    const fetchRobosList = async () => {
        try {
            const response = await getRobosList();
            const robosList = response.get_robos_list.map((item) => ({
                label: item.svmxc__serial_lot_number__c,
                value: item.sfid,
            }));
            setRoboOptions(robosList);
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        const selectedRobo = roboOptions.find((option) => option.value === caseDetails.rob__c);
        if (selectedRobo) {
            setRob(selectedRobo);
        }
    }, [caseDetails.rob__c, roboOptions]);

    useEffect(() => {
        const selectedMpsField = CRP.find((option) => option.label === caseDetails.coveringuser_present__c || option.value === caseDetails.coveringuser_present__c)
        if (selectedMpsField) {
            setMPpresent(selectedMpsField)
        } else {
            setMPpresent({label : `${current_user?.first_name} ${current_user?.last_name}`, value: `${current_user?.sfids[0]}`})
        }
    }, [CRP, caseDetails.coveringuser_present__c])
    useEffect(() => {
        if (caseDetails.coveringuser_supporting__c !== null) {
            const selectedName = caseDetails.coveringuser_supporting__c.split(',')
            const selectedSupportMpsField = CRP?.filter(item => selectedName.includes(item.value)|| selectedName.includes(item.label))
            if (selectedSupportMpsField) {
                setSupportMP(selectedSupportMpsField)
            }
        }
    }, [CRP, caseDetails.coveringuser_supporting__c])
    useEffect(() => {
        setLoader(true);
        fetchRobosList();
        fetchData();
        if (caseDetails.status === "Completed" || caseDetails.status === "Closed" || caseDetails.status === "Cancelled") {
            setDisabled(true);
        }
        setSRpresent(caseDetails.sales_rep_present__c ? (caseDetails.sales_rep_present__c === 'true' ? 'true' : 'false') : '')
        setWorkflow(caseDetails.procedure_type__c ? caseDetails.procedure_type__c : "");
    }, []);

    useEffect(() => {
        const objArray1 = CoveringRep.map((item) => ({
            value: item.id,
            label: item.title,
        }));
        setCRP(objArray1)
        if (caseDetails && caseDetails.coveringuser_supporting__c !== null) {
            const data = caseDetails.coveringuser_supporting__c
            const obj = CRP?.filter(item => data.includes(item.id))?.map(item => item.name)
            setSupportMP(caseDetails.coveringuser_supporting__c ? obj : "")
        }

    }, [CoveringRep])
    useEffect(() => {
        if (SupportMP?.length > 0) {
            const SelectedName = SupportMP.map(name => name.label)
            const arrP = CRP?.filter(item => SelectedName.includes(item.label))?.map(item => item.label).toString()
            setsuppMPS(arrP)
        } else {
            setsuppMPS("")
        }
    }, [SupportMP])

    const handleRob = (event, newValue) => {
        setRob(newValue)
    }


    return loader ? (
        <CircularProgress style={{ marginLeft: "50%", color: "gold" }} />
    ) : (
        <Grid container spacing={0} className="backgroundgreycolor">
            <Grid item xs={12} sm={6} className="backgroundgreycolor" >
                <CardContent >
                    <Typography>
                        <span style={{ fontWeight: "bold", fontSize: "16px", paddingLeft: "12px" }}>MPS Present During Surgery:<span style={{ color: 'red' }}>*</span></span>
                        <FormControl className={classes.formControl}>
                            <Autocomplete
                                disabled={disabled || disableControl}
                                value={MPpresent}
                                options={CRP}
                                getOptionLabel={(option) => option.label}
                                onChange={handleMPpresent}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Select MPS Present"
                                    />
                                )}
                            />
                        </FormControl>
                    </Typography>
                    <Typography>
                        <span style={{ fontWeight: "bold", fontSize: "16px", paddingLeft: "12px" }}>Workflow Type:</span>
                        <FormControl className={classes.formControl}>
                            <NativeSelect
                                labelId="simple-select-filled-label"
                                id="simple-select-filled"
                                value={workflow}
                                disabled={disabled || disableControl}
                                onChange={handleWorkflow}>
                                {WORKFLOW_TYPE.map(option => <option value={option.label} data-name={option.label} key={option.id}> {option.label} </option>)}
                            </NativeSelect>
                        </FormControl>
                    </Typography>
                    <Typography>
                        <span style={{ fontWeight: "bold", fontSize: "16px", paddingLeft: "12px" }}>Supporting MPS(If Any):</span>
                        <FormControl className={classes.formControl}>
                            <Autocomplete
                                multiple
                                disabled={disabled || disableControl}
                                value={SupportMP}
                                options={CRP}
                                limitTags={4}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.label}
                                onChange={(_, newValue) => handleSupportMPS(newValue)}
                                renderTags={(tagValue, getTagProps) => {
                                    return tagValue.map((option, index) =>
                                        <MyChip
                                            {...getTagProps({ index })}
                                            label={option.label}
                                        />
                                    );
                                }}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            color="primary"
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.label}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        placeholder={SupportMP?.length === 0 ? "Select Supporting MPS" : ""}
                                    />
                                )}
                            />
                        </FormControl>
                    </Typography>
                    <Typography>
                        <span style={{ fontWeight: "bold", fontSize: "16px", paddingLeft: "12px" }}>SalesRep Present During Surgery?:<span style={{ color: 'red' }}>*</span> </span>
                        <FormControl className={classes.formControl}>
                            <NativeSelect
                                labelId="simple-select-filled-label"
                                id="simple-select-filled"
                                value={SRpresent}
                                disabled={disabled || disableControl}
                                style={{ width: "30%" }}
                                onChange={handleSRpresent}>
                                {<option value="" selected>{""}</option>}
                                {<option value="true" > Yes </option>}
                                {<option value="false" > No </option>}
                            </NativeSelect>
                        </FormControl>
                    </Typography>
                </CardContent>
            </Grid>
            <Grid item xs={12} sm={6} className="backgroundgreycolor">
                <CardContent >
                    <Typography>
                        <div style={{ fontWeight: "bold", fontSize: "16px", paddingLeft: "12px", }}>ROB #:<span style={{ color: 'red' }}>*</span></div>
                        <FormControl className={classes.formControl}>
                            <Autocomplete
                                disabled={disabled || disableControl}
                                value={rob}
                                options={roboOptions}
                                getOptionLabel={(option) => option.label}
                                onChange={handleRob}
                                style={{ width: "50%" }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Select ROB"
                                    />
                                )}
                            />
                        </FormControl>
                    </Typography>
                    <Typography>
                        <div style={{ fontWeight: "bold", fontSize: "16px", paddingLeft: "12px", }}>Procedure Application Version:<span style={{ color: 'red' }}>*</span></div>
                        <FormControl className={classes.formControl}>
                            <NativeSelect
                                labelId="simple-select-filled-label"
                                id="simple-select-filled"
                                value={procedureVersion}
                                disabled={disabled || disableControl}
                                style={{ width: "50%" }}
                                onChange={(e) => {setProcedureVersion(e.target.value)}}>
                                {APPLICATION_VERSION.map(option => <option value={option.label} data-name={option.label} key={option.id}> {option.label} </option>)}
                            </NativeSelect>
                        </FormControl>
                    </Typography>
                    <Typography>
                        <div style={{ fontWeight: "bold", fontSize: "16px", paddingLeft: "12px" }}>THA Approach:</div>
                        <FormControl className={classes.formControl}>
                            <NativeSelect
                                labelId="simple-select-filled-label"
                                id="simple-select-filled"
                                value={THAapproach}
                                disabled={disabled || disableControl}
                                style={{ width: "50%" }}
                                onChange={(e) => setTHAapproach(e.target.value)}>
                                {THA_APPROACH.map(option => <option value={option.label} data-name={option.label} key={option.id}> {option.label} </option>)}
                            </NativeSelect>
                        </FormControl>
                    </Typography>
                </CardContent>
            </Grid>
            <Grid item xs={12} className={classes.gridItem} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <Buttons
                    Icon="Save"
                    toSave={true}
                    disabled={disabled || disableControl}
                    fnc={handleSubmit}
                    name="SAVE"
                />
            </Grid>
            {pop === true && <Modal
                rob={rob}
                pop={pop}
                setPop={setPop}
            />}
        </Grid >
    );
};

export default MAKOTracking;
